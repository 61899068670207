import { ProxyRequest, UserSubscribeRequest } from '@ai/api-docs-client/src/clients/SaloonApiWriter/data-contracts';
import { getSaloonApiWriter } from './client';

const defaultHeaders = { headers: { 'Content-Type': 'application/json' } };

export const postSubscribe = async (data: UserSubscribeRequest, baseApiUrl: string) => {
  const client = getSaloonApiWriter(baseApiUrl);

  const response = await client.subscribeUserPostV1ApiUsersSubscribe(data, defaultHeaders);

  return response.data;
};

export const postWarmupPostSubscribe = async (data: ProxyRequest, baseApiUrl: string) => {
  const client = getSaloonApiWriter(baseApiUrl);

  const response = await client.proxyPostV1ApiProxyWarmup(
    data,
    defaultHeaders
  );

  return response.data;
};
