import axios from 'axios';
import { V1 as SaloonApiWriter } from '@ai/api-docs-client/src/clients/SaloonApiWriter/V1';

export const getAxios = (url: string) => axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const getSaloonApiWriter = (url: string) => new SaloonApiWriter({ baseUrl: url });
